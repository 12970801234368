import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/parts/Layout";
import { snProps } from "../js/utils";
import { BlogPostMeta } from "../components/parts/BlogPostMeta";
import { BlogCommentModal } from "../components/parts/BlogCommentModal";

import * as blogStyles from "../components/global-styles/blog.module.css";

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark;

  const { title, date, comment_status, author, special_byline, tags } =
    post.frontmatter;

  const { previous, next } = pageContext;

  return (
    <Layout
      title={title}
      description={post.excerpt}
      location={{ pathname: post.fields.path }}
      isBlog={true}
      {...snProps}
    >
      <article className="post">
        <h1 className="title is-3">{title}</h1>
        <header className={blogStyles.blogHeader}>
          <BlogPostMeta
            date={date}
            author={author}
            special_byline={special_byline}
            tags={tags}
          />
        </header>
        <div
          className={`pageContent ${blogStyles.blogPostContent}`}
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <footer className={blogStyles.blogFooter}>
          {comment_status === "open" && (
            <div>
              Want to comment? Please{" "}
              <BlogCommentModal>
                <a>send it by email</a>
              </BlogCommentModal>{" "}
              and we will add it to the post.
            </div>
          )}
        </footer>
      </article>

      <nav>
        {next && (
          <>
            <div>Next Post:</div>
            <div>
              <Link
                to={next.fields.path}
                rel="next"
                className={blogStyles.nextOrPreviousLink}
              >
                {next.frontmatter.title}
              </Link>
            </div>
          </>
        )}
        {previous && (
          <>
            <div className={blogStyles.previousPostText}>Previous Post:</div>
            <div>
              <Link
                to={previous.fields.path}
                rel="prev"
                className={blogStyles.nextOrPreviousLink}
              >
                {previous.frontmatter.title}
              </Link>
            </div>
          </>
        )}
      </nav>
    </Layout>
  );
};

export const query = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(fields: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        date
        comment_status
        author
        special_byline
        tags
      }
      fields {
        path
      }
    }
  }
`;

export default BlogPost;
