import React, { useState } from "react";

export const BlogCommentModal = (props: { children: JSX.Element }) => {
  const [isActive, setIsActive] = useState(false);

  const toggleIsActive = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsActive((prev) => !prev);
  };

  return (
    <>
      <span onClick={toggleIsActive}>{props.children}</span>

      {isActive && (
        <div className={"modal is-active"}>
          <div className="modal-background" onClick={toggleIsActive}></div>
          <div
            className="modal-content"
            style={{
              fontSize: "16px",
            }}
          >
            <div className="box">
              <div className="block">
                <h3 className="title is-3">Submit a Blog Post Comment</h3>
              </div>
              <div className="block">
                To comment on a blog post, send an email to:
              </div>
              <div className="block">[...] at [...] dot org</div>
              <div className="block">
                Replace both of the missing parts with "clairnote". (Obfuscated
                to thwart spam bots.)
              </div>
              <div className="block">
                Please mention which blog post you are commenting on.
              </div>
              <div className="block">
                (Eventually we want to have a better system for this.)
              </div>
            </div>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={toggleIsActive}
          ></button>
        </div>
      )}
    </>
  );
};
